<template>
  <div class="sub-cont">
    <div class="request-tapmenu-wrap">
      <ul style="cursor: pointer">
        <li v-if="isEmployer">
          <router-link
            :class="{ on: routeName === 'JobDocumentList' }"
            to="/request"
          >
            공고별
          </router-link>
        </li>
        <li>
          <router-link
            :class="{ on: routeName === 'requestList' }"
            to="/requestList"
          >
            근무요청
          </router-link>
        </li>
        <li>
          <router-link
            :class="{ on: routeName === 'attendanceList' }"
            to="/attendance"
          >
            출 &#183; 퇴근
          </router-link>
        </li>
        <li>
          <router-link
            :class="{ on: routeName === 'mypageCalcHistory' }"
            to="/mypage/calc-history"
          >
            정산
          </router-link>
        </li>
      </ul>
    </div>
    <div class="myp-buy-wrap">
      <div class="search-item-wrap">
        <div class="label">등록일시</div>
        <div class="date-item-wrap">
          <input
            type="text"
            class="inp datepicker"
            id="reqStartDt"
            :value="reqStartDt"
            inputmode="none"
          />
          <span class="e01">-</span>
          <input
            type="text"
            class="inp datepicker"
            id="reqEndDt"
            :value="reqEndDt"
            inputmode="none"
          />
        </div>
        <div class="opt-item-wrap document-opt-wrap">
          <input
            v-model="schKeyword"
            type="text"
            class="inp"
            placeholder="공고제목 검색"
          />
        </div>
        <button type="button" @click="onGoPage(1)">검색</button>
        <label class="Deadline"
          ><input
            type="checkbox"
            v-model="Deadline"
            true-value="Y"
            false-value="N"
          /><span>진행중 공고</span></label
        >
      </div>
      <div class="docList-wrap">
        <table class="tbl01 docList">
          <colgroup>
            <col style="" />
            <col style="" />
            <col style="" />
            <col style="" />
            <col style="" />
            <col style="" />
            <col style="width: 10%" />
          </colgroup>
          <tr>
            <th>공고제목</th>
            <th>공고등록일자</th>
            <th>직종</th>
            <th>근무일자</th>
            <th>요청인원<br /><span>(모집/매칭/응답/합격)</span></th>
            <th>공고유형</th>
            <th>상태</th>
          </tr>
          <tr
            v-for="(docItem, idx) in docList"
            :key="`doc_${idx}`"
            @click="DetailParam(docItem.documentNo)"
            :class="{
              on: docItem.closingDt <= now && Deadline === 'Y',
            }"
            style="cursor: pointer"
          >
            <td>{{ docItem.jobPostTitle }}</td>
            <td>{{ docItem.regDt }}</td>
            <td>
              {{ `${docItem.jobPositionName} - ${docItem.jobPositionDtlName}` }}
            </td>
            <td style="white-space: pre">
              {{
                `${
                  docItem.workStartDate === docItem.workEndDate
                    ? docItem.workStartDate
                    : `${docItem.workStartDate}` +
                      `\n` +
                      `~ ${docItem.workEndDate}`
                }`
              }}
            </td>
            <td>
              {{
                `${docItem.positionCnt}명 / ` +
                `${docItem.scallCnt}명 / ` +
                `${docItem.answerCnt}명 / ` +
                `${docItem.passCnt}명`
              }}
            </td>
            <td>
              {{
                `${
                  docItem.boxProdId === '104'
                    ? '기본상품'
                    : docItem.boxProdId === '103'
                    ? '긴급BOX'
                    : docItem.boxProdId === '102'
                    ? '스페셜BOX'
                    : '배너BOX'
                }`
              }}
            </td>
            <td>
              <span
                class="timer"
                v-if="docItem.recruitingStatus == 'RECRUITING'"
              >
                D-{{ docItem.closingTime }}
              </span>
              <span class="timer on" v-else>모집마감</span>
            </td>
          </tr>
          <tr
            v-if="documentList.length === 0"
            style="cursor: pointer"
            @click="$router.push('/regist-job')"
          >
            <td class="mobileNone" colspan="7">
              등록된 일자리가 없습니다. 일자리를 등록해보세요.
              <img
                src="~assets/image/ico/ico_arrow_right.svg"
                alt="arrow"
                style="width: 8px; margin-left: 5px"
              />
            </td>
          </tr>
        </table>
      </div>
      <!-- 페이징 추가 -->
      <Pagination
        :totalElements="totalElements"
        :currentPageIndex="currentPageIndex"
        :listRowCount="listRowCount"
        :pageLinkCount="pageLinkCount"
        @goPage="onGoPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
// import {
//   getRequestListForEmployer,
//   getRequestListForEmployee,
// } from 'Api/modules'
import { RECRUITING_STATUS } from 'Configs/registJob'
import datePickerMixin from 'Mixins/datePicker'
import moment from 'moment'
import Pagination from 'Common/Pagination'
//추가
import { getAdvertiSementList } from 'Api/modules'
import _ from 'lodash'
export default {
  components: {
    Pagination,
  },
  mixins: [datePickerMixin],
  data() {
    return {
      workingAddress: '',
      reqStartDt: '',
      reqEndDt: '',
      workStartDt: '',
      workEndDt: '',
      schKeyword: '',

      // paging
      pageNumber: 0,
      totalElements: 0,
      listRowCount: 10,
      pageLinkCount: 5,
      currentPageIndex: 1,
      isVisibleLeftTab: true,

      //추가
      documentList: [],
      RECRUITING_STATUS: Object.freeze(RECRUITING_STATUS),
      Deadline: '',
      now: moment(new Date()).format('YYYY-MM-DD HH:mm'),
      doctNum: null,
    }
  },
  computed: {
    docList: function () {
      return _.filter(
        this.documentList,
        doctat => doctat.documentStatus !== 'CONTEMPORARY',
      )
    },
    ProgressList: function () {
      return _.filter(
        this.documentList,
        Prog =>
          Prog.documentStatus !== 'CONTEMPORARY' && Prog.closingDt > this.now,
      )
    },
  },
  watch: {
    //추가
    async visible(newVal) {
      if (newVal) {
        this.recruitsList = await getAdvertiSementList({
          userId: this.account.userId,
        })
      }
    },
    reqStartDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.reqEndDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(newVal, this.reqEndDt) - 1 < 0) {
            this.setDatePickerDate('reqEndDt', newVal, this.setEndDate)
            this.reqEndDt = newVal
          }
        }
      }
      if (
        this.diffDateCalendar(newVal, 6) === false &&
        this.isEmployer === true
      ) {
        var week = moment().subtract(7, 'days').format('YYYY-MM-DD')
        this.setDatePickerDate('reqStartDt', week, this.setStartDate)
        this.reqStartDt = week
        this.$toasted.error('검색은 최대 6개월 전까지 가능합니다.')
      }
    },
    reqEndDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.reqStartDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(this.reqStartDt, newVal) - 1 < 0) {
            this.setDatePickerDate('reqStartDt', newVal, this.setStartDate)
            this.reqStartDt = newVal
          }
        }
      }
    },
    '$route.query': {
      deep: true,
      handler() {
        this.setFromQueryParam()
        this.search(false)
      },
    },
  },
  methods: {
    onCancel() {
      this.cancelPopupVisible = true
    },
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    DetailParam(documentNo) {
      this.$router.push({
        path: '/job-detail/:ID',
        name: 'jobDetail',
        params: {
          backRoute: 'JobDocument',
          id: documentNo,
        },
      })
    },
    // DeadlineView(doctNo) {
    //   this.doctNum = doctNo
    // },
    // DeadlineHidden() {
    //   this.doctNum = null
    // },
    setStartDate(selectedDate) {
      this.reqStartDt = selectedDate
    },
    setEndDate(selectedDate) {
      this.reqEndDt = selectedDate
    },
    async search(setQuery = true) {
      //   if (!this.reqStartDt || !this.reqEndDt) {
      //     return false
      //   }
      //근무요청 목록 조회
      const res = await getAdvertiSementList({
        reqStartDt: this.reqStartDt,
        reqEndDt: this.reqEndDt,
        workStartDt: this.workStartDt,
        workEndDt: this.workEndDt,
        pushStatus: this.pushStatus,
        schKeyword: this.schKeyword,
        userId: this.account.userId,
        pageNumber: this.pageNumber,
      })
      this.documentList = res
      this.totalElements = res.totalElements
      // this.currentPageIndex = res.pageable.pageNumber + 1
      // this.pageNumber = res.pageable.pageNumber
      console.log(res)
      if (setQuery) {
        const query = {
          reqStartDt: this.reqStartDt,
          reqEndDt: this.reqEndDt,
          schKeyword: this.schKeyword,
          pageNumber: this.pageNumber,
        }

        this.$router.push({
          name: 'JobDocumentList',
          query,
        })
      }
    },

    setFromQueryParam() {
      const { reqStartDt, reqEndDt, schKeyword, pageNumber } = this.$route.query

      this.reqStartDt = reqStartDt
      this.setDatePickerDate('reqStartDt', reqStartDt, this.setStartDate)
      this.reqEndDt = reqEndDt
      this.setDatePickerDate('reqEndDt', reqEndDt, this.setEndDate)
      this.schKeyword = schKeyword
      this.pageNumber = pageNumber

      if (!this.reqStartDt && !this.reqEndDt) {
        const today = moment().format('YYYY-MM-DD')
        const weekday = moment().subtract(7, 'days').format('YYYY-MM-DD')
        this.setDatePickerDate('reqStartDt', weekday, this.setStartDate)
        this.reqStartDt = weekday
        this.setDatePickerDate('reqEndDt', today, this.setEndDate)
        this.reqEndDt = today
      }
    },
    workDate(str) {
      return String(str).substr(5)
    },
  },
  async created() {
    this.initDatePicker('reqStartDt', this.setStartDate)
    this.initDatePicker('reqEndDt', this.setEndDate)

    this.setFromQueryParam()
    this.Deadline = 'N'
    await this.search(false)
  },
}
</script>

<style lang="scss" scoped>
.tbl01 {
  tr > td:nth-child(2) {
    cursor: pointer;
  }
}
.myp-buy-wrap .search-item-wrap .label {
  width: 100px;
}
.Deadline {
  margin-left: 15px;
}
.Deadline span {
  margin-left: 5px;
}
</style>
